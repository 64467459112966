@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

@media(min-height:300px) {
    html {
        scroll-snap-type: y mandatory;
    }
}


html::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background: rgba(105, 105, 105, 0.16);
}

html::-webkit-scrollbar-corner {
     background: rgba(105, 105, 105, 0.16);
}
html::-webkit-scrollbar-thumb {
     background: red;
     -webkit-border-radius: 0.25ex;
}

.bg-filter {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}